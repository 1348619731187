<template>
  <v-container fluid>
    <v-form ref="form" @submit.prevent="validateParentBeforeSubmit">
     

      <v-data-table
        v-if="meds!=undefined && vm.doTakeMedication==true"
        id="list-table"
        ref="datatable"
        :headers="headers"
        :items="meds"
        :loading="loading"
        :hide-default-footer="true"
        item-key="index"
      >
        <template slot="item" slot-scope="props">
          <tr>
            <td>{{ props.item.medicationType}}</td>
            <td>{{ props.item.medicationName}}</td>
            <td>{{ props.item.dosage}}</td>
            <td>{{ props.item.method}}</td>
          </tr>
        </template>
      </v-data-table>
      <v-spacer></v-spacer>
      
    </v-form>
    
  </v-container>
</template>



<script>
var Models = require("../../../../participant-management/forms/models.js");
import Vue from "vue";
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);
export default {
  $_veeValidate: {
    validator: "new"
  },
  name: "medication-form",
  data: () => ({
    
    vm: new Models.Medical(),
    meds: [],
    medicationVm: {},
    
    medicationTimeMenu: false,
    totalItems: 0,
    loading: false,
    queryFilters: [],
    rowPageConfig: [
      50,
      100,
      200,
      { text: "$vuetify.dataIterator.rowsPerPageAll", value: -1 }
    ],
    headers: [
      { text: "Medication Type", value: "Type" },
      { text: "Name", value: "Name" },
      { text: "Dosage", value: "Dosage" },
      { text: "Method", value: "Method" }
    ]
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    init() {
      this.medicationVm = {};
      this.meds =
        this.currentCase.medication === undefined ||
        this.currentCase.medication.items === undefined
          ? []
          : this.currentCase.medication.items;

      this.vm =
        this.$store.getters.currentCase == undefined ||
        this.$store.getters.currentCase.medication == undefined
          ? { items: [] }
          : this.$store.getters.currentCase.medication;
    },
    editItem(item) {
      this.editedIndex = this.meds.items.indexOf(item);
      this.medicationVm = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      const index = this.meds.indexOf(item);
      if (confirm("Are you sure you want to delete this item?")) {
        this.meds.splice(index, 1);
        this.vm.items = this.meds;
        this.currentCase.medication = this.vm;
        this.$store.dispatch("setCurrentCase", this.currentCase);
      }
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.medicationVm = {};
        this.editedIndex = -1;
        this.$forceUpdate();
      }, 300);
    },
    validateParentBeforeSubmit() {
      this.$validator.validateAll("form").then(result => {
        if (result) {
          this.currentCase.medication = this.vm;
          this.$store.dispatch("setCurrentCase", this.currentCase);
          this.$emit("next-step");
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    validateBeforeSubmit() {
      this.$validator.validateAll("childForm").then(result => {
        if (result) {
          if (this.editedIndex > -1) {
            Object.assign(this.meds.items[this.editedIndex], this.medicationVm);
          } else {
            this.meds.push(this.medicationVm);
          }
          this.vm.items = this.meds;
          this.currentCase.medication = this.vm;
          this.$store.dispatch("setCurrentCase", this.currentCase);
          this.close();
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    next() {
      this.$emit("next-step");
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

